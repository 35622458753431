h3 {
  font-size: 20px;
  font-weight: bold;
  /* color: #FFFFFF; */
}
h2 {
  font-size: 24px;
  font-weight: bold;
 
}
h1 {
  font-size: 30px;
  font-weight: bold;
}
p {
  font-size: 10px;
  text-align: center;
  /* color: #ffffff; */
  margin-top: 20px;
}

#btn-submit {
  border: none;
  border-radius: 9px;
  padding: 8px 20px;
  font-weight: bold;
  background: #e16170;
  color: aliceblue;
  cursor: pointer;
  box-shadow: 0px 3px 22px 3px rgba(107, 102, 102, 0.52);
  -webkit-box-shadow: 0px 3px 22px 3px rgba(107, 102, 102, 0.52);
  -moz-box-shadow: 0px 3px 22px 3px rgba(107, 102, 102, 0.52);
}
