/* h1 {
  text-align: center;
  font-weight: bold;
  color: #425284;
}
h3 {
  text-align: center;
  color: #425284;
  line-height: 30px;
}
h2 {
  text-align: center;
  font-weight: bold;
  color: #425284;
} */
.App {
  padding-top: 50px;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
}

#main-box {
  text-align: center;
  background: #a6e1e9;
  /* height: 410px; */
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgba(107, 98, 98, 0.75);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(107, 98, 98, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(107, 98, 98, 0.75);
}
#comment-box {
  text-align: center;
  background: #a6e1e9;
  height: 465px;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgba(107, 98, 98, 0.75);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(107, 98, 98, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(107, 98, 98, 0.75);
}
#thankyou-box {
  /* text-align: center; */
  background: #a6e1e9;
  /* height: 430px; */
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgba(107, 98, 98, 0.75);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(107, 98, 98, 0.75);
  -moz-box-shadow: 0px 0px 8px 0px rgba(107, 98, 98, 0.75);
}
#stars-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
}
#csat-stars-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
#thankyoustar-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: -90px;
}

#tagline-box {
  margin-top: 30px;
}

#tagline {
  width: 340px;
  text-align: left;
  line-height: normal;
}

#textarea-box {
  width: 80%;
  margin-left: 60px;
  margin-top: 30px;
}

#btn-primary,
#btn-secondary {
  width: 87px;
  height: 36px;
  border-radius: 36px;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

#btn-primary {
  background: #ffff;
}
#btn-secondary {
  background: black;
  color: #ffff;
}
