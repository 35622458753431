#btn-poor {
  background: url('../assets/emogis/poor.svg');
  width: 50px;
  height: 50px;
  color: transparent;
  border: none;
}
#btn-fair {
  background: url('../assets/emogis/fair.svg');
  width: 50px;
  height: 50px;
  color: transparent;
  border: none;
}
#btn-average {
  background: url('../assets/emogis/average.svg');
  width: 50px;
  height: 50px;
  color: transparent;
  border: none;
}
#btn-good {
  background: url('../assets/emogis/good.svg');
  width: 50px;
  height: 50px;
  color: transparent;
  border: none;
}
#btn-great {
  background: url('../assets/emogis/great.svg');
  width: 50px;
  height: 50px;
  color: transparent;
  border: none;
}
