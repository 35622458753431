button {
  cursor: pointer;
}
#btn-0 {
  background: url('../assets/emogis/NPS/zero.svg');
  width: 28px;
  height: 28px;
  color: transparent;
  border: none;
}
#btn-1 {
  background: url('../assets/emogis/NPS/one.svg');
  width: 28px;
  height: 28px;
  color: transparent;
  border: none;
}
#btn-2 {
  background: url('../assets/emogis/NPS/two.svg');
  width: 28px;
  height: 28px;
  color: transparent;
  border: none;
}
#btn-3 {
  background: url('../assets/emogis/NPS/three.svg');
  width: 28px;
  height: 28px;
  color: transparent;
  border: none;
}
#btn-4 {
  background: url('../assets/emogis/NPS/four.svg');
  width: 28px;
  height: 28px;
  color: transparent;
  border: none;
}
#btn-5 {
  background: url('../assets/emogis/NPS/five.svg');
  width: 28px;
  height: 28px;
  color: transparent;
  border: none;
}
#btn-6 {
  background: url('../assets/emogis/NPS/six.svg');
  width: 28px;
  height: 28px;
  color: transparent;
  border: none;
}
#btn-7 {
  background: url('../assets/emogis/NPS/seven.svg');
  width: 28px;
  height: 28px;
  color: transparent;
  border: none;
}
#btn-8 {
  background: url('../assets/emogis/NPS/eight.svg');
  width: 28px;
  height: 28px;
  color: transparent;
  border: none;
}
#btn-9 {
  background: url('../assets/emogis/NPS/nine.svg');
  width: 28px;
  height: 28px;
  color: transparent;
  border: none;
}
#btn-10 {
  background: url('../assets/emogis/NPS/ten.svg');
  width: 28px;
  height: 28px;
  color: transparent;
  border: none;
}
